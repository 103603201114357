@use '@styles/theme.module' as *;

.desktopModal {
  @include media($from: $xs, $until: $sm) {
    display: none;
  }
}

.mobileModal {
  @include media($from: $sm) {
    display: none;
  }
}

.swipeArea {
  z-index: -1;
  visibility: hidden;
}

.modal {
  @include media($from: $sm) {
    width: 443px;
  }

  min-height: 490px;
}

.wrapper {
  padding-right: 0;
  padding-left: 0;
}
