@use '@styles/theme.module' as *;

.content_container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.content_wrapper {
  @include media($from: $xs, $until: $sm) {
    padding: 0 spacing(4) spacing(7);
  }

  margin-top: spacing(7);
}

.heading {
  display: block;
  text-align: center;
  @include typographyH4Medium;
}

.order {
  display: block;
  text-align: center;
  margin-top: 10px;
  @include typographyBodyMedium;
}

.info_text {
  display: block;
  text-align: center;
  margin: 16px auto 24px;
  max-width: 242px;
  color: $grayDark;
  @include typographySmallBody;

  @include media($from: $sm) {
    max-width: 272px;
  }
}

.controls_wrapper {
  @include media($from: $sm) {
    padding: spacing(0) spacing(9);
  }
}

.button {
  margin-top: 8px;
  display: block;
  width: 100%;
}

.puller {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  background-color: $white;
  height: 30px;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;

  .swipableButton {
    padding: 0 1rem;
  }

  .closeIcon {
    width: 30px;
    height: 3px;
    background-color: $black;
    opacity: 0.2;
    border-radius: 2px;
    display: inline-block;
  }

  @include media($from: $sm) {
    display: none;
  }
}
