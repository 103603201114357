@use '@styles/theme.module' as *;

.animation_container {
  margin-top: spacing(9);
  position: relative;
  width: 100%;
  min-height: 75px;
  overflow-x: hidden;
}

.animation {
  position: absolute;
  left: -50px;
  right: -50px;
  top: 50%;
  transform: translateY(-50%);
}

@keyframes car-animation {
  from {
    transform: translateX(0%);
  }

  to {
    transform: translateX(100%);
  }
}

@keyframes delivery-package-animation {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.car {
  animation: car-animation 2s linear 0s;
  animation-fill-mode: both;
}

.delivery_package {
  opacity: 0;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  animation: delivery-package-animation 1s linear 1.8s;
  animation-fill-mode: both;
}
