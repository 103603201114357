@use '@styles/theme.module.scss' as *;

.product_card_wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: transparent;
  outline: none;
  max-width: $product-tile-max-width;
  width: 100%;

  .product_card_image_wrapper {
    position: relative;
  }

  .product_card_quantityselector {
    position: absolute;
    right: spacing(1);
    bottom: spacing(1);
    z-index: 1;
  }

  .product_card_promo_cntr {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
  }

  .product_card_shoppling_list {
    position: absolute;
    top: 6px;
    right: 6px;
    z-index: 1;
    background-color: $grayF1;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    cursor: pointer;

    &:focus-visible {
      outline: 1px dashed $grayDarker;
      outline-offset: 0.25rem;
    }
  }

  .alert {
    @include typographyLabelBold;

    line-height: 1;
    @include media($from: $lg) {
      line-height: 1;
    }

    border: none;
    border-radius: 4px;
    display: flex;
    min-height: 1rem;
    margin-right: 4px;
    margin-top: calc(0.25rem);
    padding: 3px 6px;

    .wrapper {
      align-items: center;
      display: flex;

      .icon {
        flex-shrink: 0;
        height: 12px;
        width: 12px;
        margin-right: spacing(1);
      }
    }
  }

  .standing_order_btn {
    opacity: 0;
  }

  &:hover,
  &:focus-within {
    .standing_order_btn {
      opacity: 1;
    }
  }
}
