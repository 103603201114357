@use '@styles/theme.module' as *;

.ad_block_wrapper {
  &.mobile {
    min-height: var(--mobile-container-height);
  }

  @include media($from: $sm) {
    &.desktop {
      min-height: var(--desktop-container-height);
    }
  }
}

.ad_block_container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  &.bottom {
    margin-bottom: spacing(2);
  }

  & .ad_block {
    width: 100%;
    overflow: hidden;

    iframe {
      margin: 2px 0;

      @include media($from: $sm) {
        margin: 5px 0;
      }
    }
  }

  & .mobile {
    &_s {
      max-width: 320px;
    }

    &_sm {
      max-width: 186px;
    }

    &_m {
      max-width: 549px;
    }
  }

  @include media($from: $sm) {
    & .desktop {
      &_xxm {
        max-width: 728px;
      }

      &_l {
        max-width: 1408px;
      }
    }
  }
}
