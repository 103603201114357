@use '@styles/theme.module.scss' as *;
@import '@styles/media';

html,
body {
  padding: 0;
  margin: 0;
  height: 100%;
  overscroll-behavior-y: none;
}

@media print {
  body {
    padding: 16mm 0;
  }
}

body {
  overflow-x: hidden;
}

/* stylelint-disable selector-id-pattern */
#__next {
  min-height: 100vh;
}

a {
  color: inherit;
  text-decoration: none;

  &:focus {
    outline: 1px dashed #444;
    outline-offset: 4px;
  }
}

* {
  box-sizing: border-box;
  outline: none;
}

.grecaptcha-badge {
  display: none !important;
  width: 0;
  height: 0;
  opacity: 0;
}

input[type='number'] {
  appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  appearance: none;
  margin: 0;
}

.sr-only {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.link {
  &:focus {
    outline: 1px dashed #444;
    outline-offset: 4px;
  }
}

.button {
  &:focus {
    outline: 1px dashed #444;
    outline-offset: 4px;
  }

  &.Mui-focusVisible {
    outline: 1px dashed #444;
    outline-offset: 4px;
  }
}

.sr_only {
  position: absolute;
  left: -9999px;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

.developer {
  [dev-mark],
  .dev-mark {
    outline: 1px solid $redDark;

    &:hover::after {
      content: attr(data-dev-note);
      position: fixed;
      top: 0;
      left: 0;
      background: $redDark;
      color: white;
      padding: 2px;
      font-size: 10px;
      z-index: 9999;
    }
  }
}

svg {
  forced-color-adjust: auto;
}
