/* stylelint-disable */
$appBar: 1100;
$drawer: 1200;
$fab: 1050;
$mobileStepper: 1010;
$searchAutocomplete: $appBar + 5;
$modal: 1300;
$snackbar: 1400;
$speedDial: 1050;
$tooltip: 1500;
$draftIndicator: 10;

$zIndexes: (
  'appBar': $appBar,
  'drawer': $drawer,
  'fab': $fab,
  'mobileStepper': $mobileStepper,
  'modal': $modal,
  'snackbar': $snackbar,
  'speedDial': $speedDial,
  'tooltip': $tooltip,
  'searchAutocomplete': $searchAutocomplete,
  'draftIndicator': $draftIndicator,
);

@each $zIndex, $value in $zIndexes {
  :export {
    #{unquote($zIndex)}: $value;
  }
}
