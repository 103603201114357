@use '@styles/theme.module.scss' as *;

.product_card {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: $white;
  outline: none;

  &_wrapper {
    position: relative;
    height: 100%;

    &:hover .add_to_shopping_list_button {
      display: none;

      @include media($from: $lg) {
        display: block;
        opacity: 1;
      }
    }
  }

  &_main_info {
    flex: 1 1 auto;
  }

  &_promo_cnt {
    margin-top: spacing(3);
  }

  &_coupon,
  &_promo {
    flex: 1;
  }

  &_promo + &_coupon {
    margin-top: spacing(1);
  }

  &_price_block {
    display: grid;
    grid-gap: calc(#{spacing(1)} / 2);
    width: calc(
      100% - 44px - 0.25rem
    ); // 28px for quantity selector, 16px for shopping list icon, 0.25rem for padding width.
  }

  &_price_selector {
    text-align: right;
    margin-left: spacing(0);
    min-width: 32px;

    @include media($from: $sm) {
      margin-left: spacing(1);
    }
  }

  &_sub_info {
    display: flex;
    margin-top: spacing(3);
    align-items: flex-end;
    justify-content: space-between;
    position: relative;
  }

  &_shoppling_list_wrapper {
    display: flex;
    margin: spacing(0) spacing(1) spacing(1) auto;
    align-items: center;
    cursor: pointer;

    &:hover {
      path {
        stroke: $primary;
      }
    }

    &:focus-visible {
      outline: 1px dashed $grayDarker;
      outline-offset: spacing(1);
    }
  }
}

.product_hover {
  &:hover {
    .hovered_tile_image {
      background-color: rgb(0 0 0 / 0.025);
    }

    .hovered_tile_name {
      text-decoration: underline;
    }
  }
}
