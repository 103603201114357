@use '@styles/theme.module.scss' as *;

.long_unit_size {
  display: inline-block;

  span {
    border-right: 1px solid $grayLighter !important;
    padding-right: spacing(1) !important;
  }

  margin-bottom: calc(#{spacing(1)} / 2);
}

.tile_quantity_item {
  color: $grayDark;
  line-height: 1;
  border-right: 1px solid $grayLighter;
  padding-right: spacing(1);
  margin-right: spacing(1);
  @include typographySmallBody;

  &:last-child {
    border-right: 0;
    margin: 0;
    padding-right: 0;
  }
}

.tile_quantity {
  line-height: 0;
  flex: 1 1 content;

  &.v2 {
    .tile_quantity_item,
    .long_unit_size span {
      @include typographyLabel;

      color: $gray12;
      padding-right: spacing(1);
    }
  }
}
