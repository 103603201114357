/* stylelint-disable no-descending-specificity */
@use '@styles/theme.module.scss' as *;
@use 'sass:map';

$collapse-transition: 0.5s ease-in-out;

.input {
  border: none;
  background-color: transparent;
  width: 25px;
  margin: 0;
  padding: 0;
  text-align: center;
  color: $black;
  @include typographyBody;

  &_wrapper {
    display: flex;
    justify-content: center;
    width: 25px;
    flex-shrink: 0;
  }

  // Hides native arrows
  appearance: textfield;

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    appearance: none;
    margin: 0;
    opacity: 1;
  }
}

.wrapper {
  border-radius: map.get($radius, 'button');
  border: 1px solid $grayLighter;
  background-color: $white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
  transition: 0.2s ease-in-out;
  text-align: center;
  @include typographyBody;

  .button {
    height: 25px;
    width: 25px;

    * {
      color: $primary;
      width: 18px;
    }

    &:disabled {
      opacity: 0.2 !important;
    }

    &:focus-visible * {
      outline: 1px dashed $grayDarker;
      outline-offset: spacing(1);
      border-radius: 2.5rem;
    }
  }

  &.small {
    padding: 0;
    max-width: 80px;
    height: 27px;

    &.collapsed {
      width: 27px;

      &.moreThen99 {
        width: max-content;
      }
    }

    .button * {
      width: 12px;
      height: 12px;
    }
  }

  &.medium {
    padding: spacing(1) spacing(1);
    max-width: 80px;
    height: 35px;

    .button * {
      width: 12px;
      height: 12px;
    }

    &.collapsed {
      width: 35px;
    }
  }

  &.large {
    padding: spacing(2) spacing(6);
    max-width: 200px;
    height: 43px;
    @include typographyBodyBold;

    &.collapsed {
      width: 43px;
    }
  }

  &.extraLarge {
    padding: spacing(2) spacing(6);
    height: 48px;

    &.collapsed {
      width: 48px;
    }
  }

  @include hover {
    border-color: $primary;
    color: $primary;
  }

  &.full_width {
    max-width: 100%;
  }

  &.collapsed {
    cursor: pointer;
  }

  &.collapse_animate.collapsed {
    transition: $collapse-transition;
    background-color: $primary;
    border: 1px solid $primary;

    .input {
      color: $white;
    }

    &.moreThen99 {
      padding: 0 3px;

      .input {
        width: 27px;
      }
    }

    .button {
      opacity: 0;
      width: 0;
    }

    @include hover {
      width: 200px;
      background-color: $white;
      color: $black;

      .input {
        color: $black;
      }

      .button {
        opacity: 1;
        width: 25px;
      }
    }

    &:focus-within {
      width: 200px;
      background-color: $white;
      color: $black;

      .input {
        color: $black;
      }

      .button {
        opacity: 1;
        width: 25px;
      }
    }

    &.v2 {
      background-color: $groupScaleBackgoundGreen;
      height: 32px;

      .button {
        height: 32px;
        width: 32px;

        & * {
          width: 11px;
          height: 11px;
        }

        &:focus-visible {
          outline: 1px dashed $grayDarker;
          outline-offset: spacing(1);
          border-radius: 2.5rem;
        }

        &:focus-visible * {
          outline: none;
        }

        &.plus:hover {
          background-color: $primaryDark;
          box-shadow: 0 4px 4px 0 $black06;
        }
      }

      &.small {
        max-width: 96px;
      }

      .plus_buton {
        background-color: $primary;
        border-radius: 32px;
      }

      .input {
        opacity: 1;
        color: $gray12;
        font-size: 0.75rem;
        font-weight: 500;
        min-height: 30px;
      }
    }
  }
}

.v2.open {
  background-color: $groupScaleBackgoundGreen;
  width: 200px;

  &.collapsed {
    height: 32px;
    width: 32px;
  }

  &.collapse_animate {
    width: 200px;

    .button {
      opacity: 1;
    }
  }

  .input_wrapper,
  .input {
    width: 0;
    opacity: 0;
  }

  .plus_buton {
    opacity: 1;
    width: 32px;
  }

  .input,
  .input_wrapper,
  .button {
    opacity: 1;
    width: 32px;
  }
}
