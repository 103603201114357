@use '@styles/theme.module' as *;

.wrapper {
  padding: 0;
  position: sticky;
  bottom: 0;
  left: 0;
  background-color: #fff;
}

.weight {
  display: inline-flex;
  align-items: baseline;
  color: $grayDark;
}

.price_wrapper {
  margin-bottom: spacing(5);
}

.price {
  @include typographyH5Medium;

  @include media($from: $lg) {
    @include typographyH4Medium;
  }
}

.product_page_price_previous,
.product_page_price_percents {
  @include typographyH5;

  @include media($from: $lg) {
    @include typographyH4;
  }
}

.product_page_price_previous {
  color: $grayDark;
  margin-left: spacing(1);
}

.product_page_price_percents {
  color: $sale;
  margin-left: spacing(2);
}

.counter {
  width: 100%;

  &:not(:last-child) {
    margin-right: spacing(2);
  }
}

.info_count {
  color: $grayDark;
}

.info_icon {
  height: 12px;
  width: 12px;
  margin-left: spacing(1);
  cursor: pointer;

  &:focus-visible {
    outline: 1px dashed $grayDarker;
  }

  &:focus:not(:focus-visible) {
    outline: none;
  }
}

.content {
  @include media($from: $sm) {
    width: 652px;
  }

  @include media($from: $lg) {
    width: 720px;
  }
}

.window_disclaimer {
  table {
    width: 100%;
  }
}

.counter_wrapper {
  margin-top: spacing(5);
  margin-bottom: spacing(5);
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}

.item {
  width: 100%;

  &:not(:last-child) {
    margin-right: spacing(2);
  }
}

.add_to_shopping_list_button {
  display: flex;
  margin-top: spacing(5);
  align-items: center;
  cursor: pointer;
  width: fit-content;

  svg {
    margin-right: spacing(2);
  }

  &:hover {
    svg path,
    span {
      color: $primary;
      stroke: $primary;
    }
  }

  &:focus-visible {
    outline: 1px dashed $grayDarker;
    outline-offset: spacing(1);
  }
}

.skeleton {
  &_text {
    width: 100%;
    height: 24px;
    margin-bottom: spacing(4);
  }

  &_button {
    width: 100%;
    height: 48px;
    border-radius: 30px;
  }
}

.regular_express_overlay {
  margin: 0 !important;

  @include media($until: $sm) {
    margin: 0 0 spacing(11) 0 !important;
  }
}

.total_price_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: spacing(4);
}
