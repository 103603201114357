@use '@styles/theme.module.scss' as *;

.title {
  margin-bottom: spacing(4);
}

.description {
  display: inline-block;
  margin-bottom: spacing(4);
}
