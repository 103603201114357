@use '@styles/theme.module.scss' as *;

.wrapper {
  width: 50px;
  height: 12px;

  @include media($from: $xl) {
    width: 60px;
    height: 12px;
    padding-left: spacing(1);
  }

  &_block {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  &_inline {
    display: inline-block;

    // hack for icon with padding inside viewbox
    @include media($from: $xl) {
      padding-top: 1px;
    }
  }
}

.icon {
  width: 50px;
  height: 12px;
  display: block;
  color: $deliveryPassFG;

  @include media($from: $xl) {
    transform: scale(1.2);
  }
}
