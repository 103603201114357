@use '@styles/theme.module' as *;

$copyright-footer-height: 34px;
$bag-padding-top: 30px;

.wrapper {
  display: flex;
  width: 100%;
  height: 100%;

  &.wrapper.drawer_opened {
    padding: 0;
    background-color: $gray1;
    border-right: none;
    grid-area: inner;
    overflow: hidden;
  }
}

.inner {
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  margin: 0 auto;
  padding: spacing(6) spacing(4) spacing(15);
  overflow: auto;

  @include media($from: $sm) {
    margin: 0;
    padding: spacing(9) spacing(14) spacing(15) spacing(14);
  }

  @include media($from: $largeTablet) {
    margin: spacing(0);
    width: 100%;
    padding: $bag-padding-top spacing(2) spacing(10) spacing(2);
  }

  @include media($from: $lg) {
    padding: $bag-padding-top spacing(8) spacing(10) spacing(8);
  }

  @include media($from: $xl) {
    padding: $bag-padding-top spacing(13) spacing(10) spacing(12);
  }

  &.inner_empty {
    min-height: calc(100vh - $copyright-footer-height);
  }

  &.drawer_opened {
    padding: 0;
    overflow: auto;
  }

  &.drawer_opened.with_tabs {
    display: grid;
    grid-template-rows: auto 1fr auto;
    grid-template-areas:
      'headline'
      'nav'
      'main'
      'footer';
  }
}

.inner_empty {
  min-height: 100vh;
}

.headline {
  padding-bottom: spacing(5);
  border-bottom: 1px solid $grayLighter;
  @include typographyH3Medium;

  @include media($from: $md) {
    margin-bottom: spacing(2);
  }

  @include media($from: $largeTablet) {
    margin-bottom: spacing(6);
  }

  @include media($from: $xl) {
    margin-left: spacing(5);
    margin-bottom: spacing(7);
  }
}

.title {
  color: $black;
}

.title_counter {
  color: $grayDark;
}

.linkline {
  display: flex;
  justify-content: space-between;
  margin-bottom: spacing(8);

  @include media($from: $sm) {
    padding-left: spacing(5);
    margin-bottom: spacing(9);
  }

  @include media($from: $largeTablet) {
    margin-bottom: spacing(5);
  }
}

.with_recommended {
  margin-bottom: spacing(8);
  padding-left: 0;

  @include media($from: $sm) {
    margin-bottom: spacing(9);
  }

  @include media($from: $largeTablet) {
    margin-bottom: spacing(10);
  }
}

.linkline_item {
  color: $black;
  text-decoration: underline;
}

.login_button,
.continue_button {
  border: none;
  display: inline;
  margin-top: -#{spacing(1)};
  @include typographyBody;

  &::before {
    display: none;
  }
}

.continue_button {
  color: $black;
}

.wrapper.is_gift_cards .inner {
  @include media($until: $sm) {
    margin: 0;
    width: 100%;
  }
}

.sidecart_header {
  padding: spacing(7) spacing(5);
  background-color: $gray1;
  grid-area: header;
}

.close_button {
  position: absolute;
  color: $black;
  background-color: transparent !important;

  @include media($until: $sm) {
    right: 12px;
    top: 27px;
  }

  @include media($from: $sm) {
    right: 27px;
    top: 27px;
  }

  > svg {
    @include media($until: $sm) {
      height: 22px;
    }

    @include media($from: $sm) {
      height: 17px;
    }
  }

  @include hover {
    & svg {
      color: $primary !important;
    }
  }

  &:global(.Mui-focusVisible) {
    color: $white;
    outline: 1px dashed $grayDark;
    outline-offset: spacing(1);
  }
}

.nav_wrapper {
  background-color: $gray1;

  &:not(.drawer_opened) {
    padding: spacing(6) spacing(6) 0;
  }

  &.drawer_opened {
    background-color: $white;
  }
}

.continue_shopping_section {
  margin: -#{spacing(8)} -#{spacing(8)} spacing(6);
  padding: spacing(8) spacing(8) 0 spacing(8);
  background-color: $white;

  @include media($until: $sm) {
    margin-right: -#{spacing(4)};
    margin-left: -#{spacing(4)};
  }

  @media (min-height: 900px) {
    margin-bottom: spacing(0);
  }
}

.recommend_carousel_wrapper {
  margin: 0 -#{spacing(8)} spacing(6);
  padding: spacing(8) spacing(8) 0 spacing(8);
  background-color: $white;
  order: 2;

  @include media($until: $sm) {
    margin-right: -#{spacing(4)};
    margin-left: -#{spacing(4)};
  }

  @media (min-height: 900px) {
    margin-top: -#{spacing(8)};
    order: 1;
  }
}

.bag_wrapper {
  display: flex;
  flex-direction: column;
  order: 1;

  &.drawer_opened {
    height: 100%;
  }

  @media (min-height: 900px) {
    order: 2;
  }
}
